import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { Form, Input, message, Checkbox, Select } from 'antd'
import { auth0Roles, strapiURL } from '../../../config'
import { formatPrice, returnCreditType } from '../../../functions'
import cookie from 'react-cookies'
import { navigate } from 'gatsby'
import { MinusIcon, PlusIcon, StepCheckIcon } from '../../icons'
import { useDispatch } from 'react-redux'
import { setProfileData, setProducts } from '../../../redux/actions/user'
import globalData from '../../../../globalData.json'
import useGMT from '../../../hooks/useGTM'

let selectedPriceId

const EmployerJoinHero = () => {
  const [step, setStep] = useState(1)
  const [listingPrices, setListingPrices] = useState([])
  const [checkoutData, setCheckoutData] = useState({})
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [totalAmount, setTotalAmount] = useState(0)
  const [employerForm] = Form.useForm()
  const [staffingFirm, setStaffingFirm] = useState('')
  const { Option } = Select
  const dispatch = useDispatch()
  let employerSubscriptionItems = globalData.EmployerCatalog.prices || []
  const {
    pushEmployerViewItemEvent,
    pushEmployerSelectItemEvent,
    pushEmployerSelectAddonEvent,
    pushEmployerAddToCartSubscriptionEvent,
    pushEmployerAddToCartAddonEvent,
    pushEmployerCheckoutEvent,
    pushRegisterEvent,
  } = useGMT()

  const navbarItems = [
    {
      label: 'Select Plan',
      id: 1,
    },
    {
      label: 'Choose add-ons',
      id: 2,
    },
    {
      label: 'Register',
      id: 3,
    },
    {
      label: 'Make payment',
      id: 4,
    },
  ]
  useEffect(() => {
    window.scrollTo(0, 0)
    if (cookie.load('user') || cookie.load('userRole')) {
      navigate('/')
    }
    if (employerSubscriptionItems) {
      let filterdItems = employerSubscriptionItems.filter(
        item => !item.isDiscontinued
      )
      setListingPrices(filterdItems || [])
    }
  }, [step])

  const stepsHandler = value => {
    let _checkoutDataCopy = { ...checkoutData }

    // Subscription Step
    if (step === 1) {
      if (value?.price?.id === _checkoutDataCopy?.subscription?.id) {
        _checkoutDataCopy = {}
      } else {
        _checkoutDataCopy.subscription = value.price
        _checkoutDataCopy.currentAddons = value?.addOns || []
      }
    }
    setCheckoutData(_checkoutDataCopy)
  }

  const addonsQuantityHandler = (val, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]
    if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
      'resumeSearch'
    ) {
      if (_currentAddons[addonIndex].is_selected) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
    } else if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
      'featuredJob'
    ) {
      if (parseInt(val) <= 0) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
      _currentAddons[addonIndex].quantity = val
    } else {
      _currentAddons[addonIndex].quantity = val
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }

  const featuredJobQuantityHandler = (currentAddon, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]

    if (currentAddon.quantity || currentAddon.is_selected) {
      _currentAddons[addonIndex].is_selected = false
      _currentAddons[addonIndex].quantity = 0
    } else {
      _currentAddons[addonIndex].is_selected = true
      _currentAddons[addonIndex].quantity = 1
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }

  const quantityHandler = (isPrice, val, addonIndex) => {
    let _currentAddons = [...checkoutData.currentAddons]
    let _currentSubscription = { ...checkoutData.subscription }
    if (isPrice) {
      if (val) {
        _currentSubscription.quantity = val
        setCheckoutData({ ...checkoutData, subscription: _currentSubscription })
      } else {
        setCheckoutData({})
      }
      return
    }
    if (
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
        'resumeSearch' ||
      returnCreditType(_currentAddons[addonIndex].credit_type).type ===
        'addonSubscription'
    ) {
      if (_currentAddons[addonIndex].is_selected) {
        _currentAddons[addonIndex].is_selected = false
      } else {
        _currentAddons[addonIndex].is_selected = true
      }
    } else {
      _currentAddons[addonIndex].quantity = val
    }

    setCheckoutData({ ...checkoutData, currentAddons: _currentAddons })
  }
  useEffect(() => {
    let total = 0

    checkoutData?.currentAddons?.forEach(addon => {
      if (
        ((returnCreditType(addon.credit_type).type === 'resumeSearch' ||
          returnCreditType(addon.credit_type).type === 'addonSubscription') &&
          addon.is_selected) ||
        addon.quantity
      ) {
        if (addon.quantity) {
          total = total + parseInt(addon.amount) * addon.quantity
        } else {
          total = total + parseInt(addon.amount)
        }
      }
    })
    if (checkoutData.subscription) {
      if (
        checkoutData.subscription?.amount &&
        checkoutData.subscription?.quantity
      ) {
        total =
          total +
          parseInt(checkoutData.subscription?.amount) *
            checkoutData.subscription?.quantity
      } else {
        total = total + parseInt(checkoutData.subscription.amount)
      }
    }
    setTotalAmount(total)
  }, [checkoutData])

  useEffect(() => {
    if (step === 1) {
      setCheckoutData({})
    }
    if (step < 3) {
      employerForm.resetFields()
    }

    if (step === 3) {
      pushEmployerCheckoutEvent(
        checkoutData.subscription?.priceId,
        checkoutData.currentAddons
      )
    }
  }, [step])

  const userCheckhandler = values => {
    const regex =
      /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])|(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])).{8,16}$/

    if (values.confirm.length < 8 || !values.confirm.match(regex)) {
      message.error(
        'Your password must contain at least 8 characters including at least 3 of the following 4 types of characters: a lower-case letter, an upper-case letter, a number, a special character (such as !@#$%^&*).'
      )
      return
    }

    pushRegisterEvent(false)

    setSubmitLoading(true)
    let data = {
      email: (values.email || '').toLowerCase(),
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      last_login: new Date(),
      roleId: auth0Roles.Employer.id,
      marketing_preferences: values.marketing_preferences ? true : false,
      companyName: values.name,
      staffing_firm: staffingFirm === 'yes' ? true : false,
    }

    axios
      .post(strapiURL + '/auth0/signup', data)
      .then(res => {
        const userData = res.data
        const responseRole = userData.role.id
        let profile = userData?.profile
        delete userData.profile

        cookie.save('userRole', responseRole, { path: '/', maxAge: 259200 })
        cookie.save('user', userData, { path: '/', maxAge: 259200 })

        dispatch(setProfileData(profile))

        if (!checkoutData.subscription && typeof window !== 'undefined') {
          window.location.href = '/employer/membership/'
        } else if (!userData.isCheckoutRequired) {
          alert('You already have a subscription with us.')

          // Retrive updated subscription
          axios
            .get(
              strapiURL +
                `/employers/${userData?.user?.id}/subscriptionsAndCredits`
            )
            .then(res => {
              dispatch(setProducts(res?.data))
              navigate('/employer-dashboard/', {
                state: { firstRegistration: true },
              })
            })
            .catch(err => {
              console.log(
                'Something wrong while retrive subscriptionsAndCredits.'
              )
            })
        } else {
          localStorage.setItem(
            'emp-subscription',
            JSON.stringify({
              subscription: checkoutData.subscription,
              addons: checkoutData.currentAddons,
            })
          )

          let mode = 'payment'
          let url =
            '/thankyou/?success=true&session_id={CHECKOUT_SESSION_ID}&first_registration=true'
          let _checkoutPrices = []
          if (typeof window !== 'undefined') {
            _checkoutPrices.push({
              price: checkoutData.subscription.priceId,
              quantity: checkoutData.subscription.recurring
                ? 1
                : checkoutData.subscription.quantity,
            })
            if (checkoutData.subscription.recurring) {
              mode = 'subscription'
            } else {
              url += '&employerRedirect=post'
            }
            if (checkoutData?.currentAddons?.length) {
              for (const item of checkoutData?.currentAddons) {
                if (
                  returnCreditType(item.credit_type).type === 'resumeSearch' ||
                  returnCreditType(item.credit_type).type ===
                    'addonSubscription'
                ) {
                  if (item.is_selected) {
                    _checkoutPrices.push({
                      price: item.priceId,
                      quantity: 1,
                    })
                    mode = 'subscription'
                  }
                } else {
                  if (item.quantity) {
                    _checkoutPrices.push({
                      price: item.priceId,
                      quantity: item.quantity,
                    })
                  }
                }
              }
            }
          }

          axios
            .post(strapiURL + '/stripe/session/checkout', {
              line_items: _checkoutPrices,
              success_url: window.location.origin + url,
              cancel_url: window.location.origin,
              customerEmail: (values.email || '').toLowerCase(),
              mode: mode,
              clientId: cookie.load('_ga')?.substring(6),
              sessionId: cookie
                .load(
                  process.env.GATSBY_GOOGLE_ANALYTICS_MEASUREMENT_ID.replace(
                    'G-',
                    '_ga_'
                  )
                )
                ?.split('.')[2],
            })
            .then(res => {
              if (typeof window !== 'undefined') {
                window.history.replaceState(
                  '',
                  '',
                  `${window.location.origin}/thankyou/`
                )
                window.location.href = res?.data?.url
              }
            })
            .catch(err => {
              message.error('Something wrong while checkout.')
            })
        }
      })
      .catch(err => {
        message.error(err?.response?.data?.message[0].error)
        setSubmitLoading(false)
      })
  }
  useEffect(() => {
    if (step === 1) {
      pushEmployerViewItemEvent('2')
    } else if (step === 2 && checkoutData.subscription?.priceId) {
      pushEmployerViewItemEvent('3', checkoutData.subscription?.priceId)
    }
  }, [step])

  useEffect(() => {
    if (step === 1) {
      if (
        checkoutData.subscription?.priceId &&
        selectedPriceId !== checkoutData.subscription?.priceId
      ) {
        pushEmployerSelectItemEvent(checkoutData.subscription?.priceId)
        selectedPriceId = checkoutData.subscription?.priceId
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutData])

  const handleGTMTracking = addon => {
    pushEmployerSelectAddonEvent(addon)
  }

  const EmployerForm = () => {
    return (
      <div className="w-full">
        <Form
          form={employerForm}
          name="candidate-form"
          layout="vertical"
          onFinish={userCheckhandler}
        >
          <Form.Item
            name="email"
            label="Email"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Company Name"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="First Name"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Last Name"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Required.',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            dependencies={['password']}
            label="Confirm password"
            className="theme-form-item"
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  )
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="agree"
            className="theme-form-item"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('Required')),
              },
            ]}
          >
            <div className="flex items-start">
              <span className="mr-[4px] text-xl text-[#ff4d4f]">*</span>
              <Checkbox>
                I have read and agree to the MedReps Terms & Conditions
                <a
                  className="text-black text-[12px] font-bold opacity-90 ml-3 inline-block"
                  href="/privacy-policy"
                  target="_blank"
                >
                  {' '}
                  (View)
                </a>
              </Checkbox>
            </div>
          </Form.Item>
          <p className="pt-0 font-sans text-last mb-4">
            By creating a MedReps account, you agree to receive marketing
            communications, updates, and relevant information about our services
            and offers. We are committed to respecting your preferences, and you
            can unsubscribe from these communications at any time by following
            the link provided in our emails or contacting our support team. Your
            privacy is important to us, and we ensure that your information is
            safe and handled in accordance with{' '}
            <a href="/privacy-policy" target="_blank">
              MedReps' privacy policy
            </a>
            .
          </p>
          <button
            type="submit"
            className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
          >
            {submitLoading
              ? 'Loading...'
              : checkoutData.subscription
              ? 'Checkout'
              : 'Signup'}
          </button>
        </Form>
      </div>
    )
  }
  return (
    <>
      <div className="container mx-auto pt-12 pb-16">
        <div className="px-[30px] w-full lg:px-0 lg:w-4/5 mx-auto">
          <div className="w-5/5 lg:w-3/5">
            {/* navbar Area */}
            {step > 0 && (
              <div className="navbar-area">
                <div className="flex items-center relative pb-6">
                  {step > 0 ? (
                    <img
                      onClick={() => {
                        if (step > 1) {
                          if (step === 3) {
                            if (!checkoutData.subscription) {
                              setStep(1)
                              return
                            }
                          }
                          setStep(step - 1)
                        } else {
                          navigate('/hiring-with-medReps/')
                        }
                      }}
                      src="/icons/icon-back-red.svg"
                      className="w-4 mr-2 cursor-pointer"
                    />
                  ) : (
                    <div />
                  )}
                  <h1 className="text-lg font-sans font-semibold text-merlot mb-0">
                    Post jobs with Medreps
                  </h1>
                </div>
                <div className="flex">
                  {navbarItems?.map((navItem, navIndex) => {
                    return (
                      <div className="membership-step flex-1" key={navIndex}>
                        <div className="flex items-center">
                          <div>
                            {step >= navItem.id ? (
                              <div className="active h-6 w-6 bg-[#059913] rounded-full flex items-center justify-center">
                                {step === navItem.id ? (
                                  <div className="h-2 w-2 bg-white rounded-full" />
                                ) : (
                                  <StepCheckIcon />
                                )}
                              </div>
                            ) : (
                              <div className="inactive bg-border h-6 w-6 bg-[#E1E1E1] rounded-full" />
                            )}
                          </div>
                          {navIndex !== navbarItems?.length - 1 && (
                            <div className="divider flex-1 h-px bg-border" />
                          )}
                        </div>
                        <p className="text-xs font-semibold font-sans mt-4">
                          {navItem.label}
                        </p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </div>
          {/* navbar Area */}
          {loading ? (
            <div
              className="w-5/5 lg:w-3/5 min-h-700px bg-cover bg-center bg-no-repeat mt-16"
              style={{
                backgroundImage: `url(/loaders/employer-products-loader.png)`,
              }}
            />
          ) : (
            <div>
              {step === 3 && (
                <div>
                  <h1 className="text-4xl font-playfair pt-8">
                    Register your MedReps Job Posting Account
                  </h1>
                  <p className="text-xl">
                    You can add more users after you have registered your
                    account
                  </p>
                </div>
              )}
              <div className="flex flex-col-reverse lg:flex-row gap-0 lg:gap-12">
                <div className="w-full lg:w-5/12">
                  {/* Steps Area */}
                  {/* Step 1 */}
                  {step === 1 && (
                    <div className="pt-12 lg:pt-16">
                      <h1 className="font-playfair font-normal text-headingFour lg:text-headingThree mb-2">
                        Choose your job posting plan
                      </h1>
                      <p className="text-sm font-sans">Cancel anytime</p>
                      <div className="options-div">
                        {listingPrices?.map((price, priceIndex) => {
                          let isSelected =
                            checkoutData?.subscription?.id === price?.price?.id

                          return (
                            <div
                              onClick={() => {
                                if (
                                  returnCreditType(price.price.credit_type)
                                    .type === 'singleJob' &&
                                  !isSelected
                                ) {
                                  stepsHandler({
                                    ...price,
                                    price: {
                                      ...price.price,
                                      quantity: 1,
                                    },
                                  })
                                } else if (
                                  returnCreditType(price.price.credit_type)
                                    .type !== 'singleJob'
                                ) {
                                  stepsHandler(price)
                                }
                              }}
                              key={priceIndex}
                              className={`mb-8 single-option bg-white shadow-md flex justify-between items-center p-4 rounded-md cursor-pointer border-2 border-transparent ${
                                isSelected
                                  ? 'border-2 border-merlot active-card-shadow'
                                  : ''
                              }`}
                            >
                              <div className="flex-1 flex items-center">
                                {isSelected ? (
                                  <div className="flex items-center justify-center active bg-merlot h-6 lg:h-9 w-6 lg:w-9 bg-merlot rounded-full flex items-center justify-center">
                                    <img
                                      src="/icons/check-mark.svg"
                                      className="mb-0"
                                    />
                                  </div>
                                ) : (
                                  <div className="inactive bg-border h-6 lg:h-9 w-6 lg:w-9 bg-[#E1E1E1] rounded-full" />
                                )}

                                <div className="ml-4 flex-1">
                                  <p className="font-sans font-bold text-lg mb-0">
                                    {price?.price?.nickname || price?.name}
                                  </p>
                                  <p className="font-sans text-xs mb-1">
                                    {price?.price?.description}
                                    <span className="text-[#c40000]">
                                      {' '}
                                      {price?.price?.savesUpto
                                        ? `${price?.price?.savesUpto}`
                                        : ''}
                                    </span>
                                  </p>
                                </div>
                              </div>
                              <div className="ml-6">
                                <p
                                  className={`${
                                    isSelected ? 'text-merlot' : 'text-black'
                                  } font-sans font-bold text-lg mb-0 text-right`}
                                >
                                  <span>
                                    {formatPrice(price?.price?.amount, 'USD')}
                                  </span>
                                </p>
                                <p
                                  className={`${
                                    isSelected ? 'text-merlot' : 'text-black'
                                  } font-sans font-bold text-lg mb-0`}
                                >
                                  <span>{price?.price?.duration}</span>
                                </p>
                                {returnCreditType(price.price.credit_type)
                                  .type === 'singleJob' ? (
                                  <div className="flex items-center mt-2">
                                    <button
                                      className="p-4 cursor-pointer"
                                      onClick={() => {
                                        if (
                                          checkoutData?.subscription?.quantity
                                        ) {
                                          quantityHandler(
                                            true,
                                            checkoutData?.subscription
                                              ?.quantity - 1
                                          )
                                        }
                                      }}
                                    >
                                      <div className="z-30">
                                        <MinusIcon />
                                      </div>
                                    </button>
                                    <span className="text-lg mx-2 text-charcoal">
                                      {checkoutData?.subscription?.quantity ||
                                        0}
                                    </span>
                                    <button
                                      className="p-4 cursor-pointer"
                                      onClick={() => {
                                        if (
                                          !checkoutData.subscription ||
                                          returnCreditType(
                                            checkoutData?.subscription
                                              ?.credit_type
                                          ).type !== 'singleJob'
                                        ) {
                                          stepsHandler({
                                            ...price,
                                            price: {
                                              ...price.price,
                                              quantity: 1,
                                            },
                                          })
                                          return
                                        }
                                        if (
                                          returnCreditType(
                                            checkoutData?.subscription
                                              ?.credit_type
                                          ).type === 'singleJob'
                                        ) {
                                          quantityHandler(
                                            true,
                                            checkoutData?.subscription?.quantity
                                              ? checkoutData?.subscription
                                                  ?.quantity + 1
                                              : 1
                                          )
                                        }
                                      }}
                                    >
                                      <div className="z-30">
                                        <PlusIcon />
                                      </div>
                                    </button>
                                  </div>
                                ) : (
                                  <div />
                                )}
                              </div>
                            </div>
                          )
                        })}
                        <div className="my-4">
                          <div className="ant-form-item-required mb-2">
                            Is your organization a recruitment/staffing company?
                          </div>
                          <Select
                            value={staffingFirm}
                            onChange={setStaffingFirm}
                            className="w-full"
                            size="large"
                          >
                            <Option value="">Select</Option>
                            <Option value="yes">Yes</Option>
                            <Option value="no">No</Option>
                          </Select>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          if (!checkoutData.subscription) {
                            return message.info(
                              'Please select a plan to continue.'
                            )
                          }

                          if (staffingFirm === '') {
                            return message.info(
                              'Please select a organization type.'
                            )
                          }
                          pushEmployerAddToCartSubscriptionEvent(
                            checkoutData.subscription.priceId
                          )
                          setStep(2)
                        }}
                        className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                      >
                        Next
                      </button>
                    </div>
                  )}
                  {step === 2 && (
                    <>
                      <div className="pt-12 lg:pt-16">
                        <h1 className="font-playfair font-normal text-headingFour lg:text-headingThree mb-2">
                          Choose Add-Ons
                        </h1>
                        <p className="text-sm font-sans">
                          Optimize your recruitment with these smart add-ons
                        </p>
                        {checkoutData.currentAddons.map(
                          (currentAddon, currIndex) => {
                            if (
                              returnCreditType(currentAddon.credit_type)
                                .type === 'addonSubscription'
                            ) {
                              if (
                                staffingFirm === 'no' &&
                                currentAddon.staffing
                              ) {
                                return null
                              }

                              if (
                                staffingFirm === 'yes' &&
                                !currentAddon.staffing
                              ) {
                                return null
                              }
                            }
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    if (
                                      returnCreditType(currentAddon.credit_type)
                                        .type === 'resumeSearch' ||
                                      returnCreditType(currentAddon.credit_type)
                                        .type === 'addonSubscription'
                                    ) {
                                      quantityHandler(false, '', currIndex)
                                    } else if (
                                      returnCreditType(currentAddon.credit_type)
                                        .type === 'featuredJob'
                                    ) {
                                      featuredJobQuantityHandler(
                                        currentAddon,
                                        currIndex
                                      )
                                    }

                                    if (
                                      currentAddon.is_selected ||
                                      currentAddon.quantity === 1
                                    ) {
                                      handleGTMTracking(
                                        checkoutData.currentAddons
                                      )
                                    }
                                  }}
                                  key={currIndex}
                                  className={`mb-8 single-option bg-white shadow-md flex justify-between items-center px-4 py-8 rounded-md cursor-pointer border-2 border-transparent ${
                                    currentAddon.quantity ||
                                    currentAddon.is_selected
                                      ? 'border-2 border-merlot active-card-shadow'
                                      : ''
                                  }`}
                                >
                                  <div className="flex lg:items-center">
                                    {currentAddon.quantity ||
                                    currentAddon.is_selected ? (
                                      <div>
                                        <div className="flex items-center justify-center active bg-merlot h-9 w-9 bg-merlot rounded-full flex items-center justify-center">
                                          <img
                                            src="/icons/check-mark.svg"
                                            className="mb-0"
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="inactive bg-border h-9 w-9 bg-[#E1E1E1] rounded-full flex items-center justify-center" />
                                      </div>
                                    )}

                                    <div className="ml-4 w-8/12">
                                      <p className="font-sans font-bold text-lg mb-2">
                                        {currentAddon.nickname}
                                      </p>
                                      <p className="font-sans text-[#c40000] text-xs mb-1">
                                        {currentAddon?.red_desc || ''}
                                      </p>
                                      <p className="font-sans text-xs text-black  mb-0">
                                        {currentAddon?.description || ''}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="flex flex-col justify-center items-center">
                                    <p
                                      className={`${
                                        currentAddon.quantity ||
                                        currentAddon.is_selected
                                          ? 'text-merlot'
                                          : 'text-black'
                                      } font-sans font-bold text-lg mb-0 w-max`}
                                    >
                                      {formatPrice(currentAddon?.amount, 'USD')}
                                      &nbsp;
                                      {currentAddon?.metadata?.price_text || ''}
                                    </p>
                                    {returnCreditType(currentAddon.credit_type)
                                      .type === 'featuredJob' ? (
                                      <div className="flex items-center mt-2">
                                        <button
                                          className="p-4 cursor-pointer"
                                          onClick={e => {
                                            if (currentAddon.quantity) {
                                              addonsQuantityHandler(
                                                currentAddon.quantity - 1,
                                                currIndex
                                              )
                                            }
                                            e.stopPropagation()
                                          }}
                                        >
                                          <MinusIcon />
                                        </button>
                                        <span className="text-lg mx-2 text-charcoal">
                                          {currentAddon.quantity || 0}
                                        </span>
                                        <button
                                          className="p-4 cursor-pointer"
                                          onClick={e => {
                                            addonsQuantityHandler(
                                              currentAddon.quantity
                                                ? currentAddon.quantity + 1
                                                : 1,
                                              currIndex
                                            )
                                            if (currentAddon.quantity === 1) {
                                              handleGTMTracking(
                                                checkoutData.currentAddons
                                              )
                                            }
                                            e.stopPropagation()
                                          }}
                                        >
                                          <PlusIcon />
                                        </button>
                                      </div>
                                    ) : (
                                      <div />
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          }
                        )}
                        <button
                          onClick={() => {
                            setStep(3)
                            pushEmployerAddToCartAddonEvent(
                              checkoutData.currentAddons
                            )
                          }}
                          className="bg-voyage w-full py-4 text-white font-sans font-bold mb-0"
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                  {/* Step 1 */}
                  {/* Step 2 */}
                  {step === 3 && (
                    <div className="pt-6 lg:pt-8">
                      <EmployerForm />
                    </div>
                  )}
                  {/* 2 */}
                  {/* Steps Area */}
                </div>
                {step !== 1 && (
                  <div className="pt-12 lg:pt-16">
                    {step !== 3 && (
                      <div className="hidden lg:block opacity-0">
                        <h1 className="font-playfair font-normal text-headingThree mb-2">
                          Choose your job posting plan
                        </h1>
                        <p className="text-sm font-sans">Cancel anytime</p>
                      </div>
                    )}

                    {totalAmount && (step === 2 || step === 3) ? (
                      <div className="shadow-profileCard px-4 py-8 rounded">
                        <p className="text-merlot font-sans font-bold text-lg">
                          Your Purchase
                        </p>
                        <div className="mb-3">
                          <p className="font-sans font-bold text-lg mb-0">
                            {checkoutData?.subscription?.nickname}
                          </p>
                          <p className="font-sans text-xs font-normal mb-0">
                            {checkoutData?.subscription?.quantity
                              ? checkoutData?.subscription?.quantity +
                                ' ' +
                                'credit(s) purchased'
                              : ''}
                            <span className="ml-1 font-bold">
                              {checkoutData?.subscription?.amount &&
                                checkoutData?.subscription?.quantity &&
                                formatPrice(
                                  checkoutData?.subscription?.amount *
                                    checkoutData?.subscription?.quantity,
                                  'Usd'
                                )}
                            </span>
                          </p>

                          <p className="font-sans text-xs font-normal mb-0">
                            {checkoutData?.subscription?.description}
                          </p>
                        </div>

                        {checkoutData?.currentAddons
                          ?.filter(item => item.is_selected || item.quantity)
                          .map((addon, addonIndex) => {
                            let addonType = returnCreditType(
                              addon.credit_type
                            ).type

                            return (
                              <div className="mb-3" key={addonIndex}>
                                <p className="font-sans font-bold text-lg mb-0">
                                  {addon?.nickname}
                                </p>

                                <p className="font-sans text-xs font-normal mb-0">
                                  {addonType === 'resumeSearch' ||
                                  addonType === 'addonSubscription'
                                    ? addon.black_description
                                    : addon.quantity +
                                      ' ' +
                                      'credit(s) purchased'}
                                </p>
                              </div>
                            )
                          })}
                        <p className="font-sans font-bold text-lg mb-0 mt-6">
                          {formatPrice(totalAmount, 'Usd')}
                        </p>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
export default EmployerJoinHero
